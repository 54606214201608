import './ExploreContainer.css';

interface ContainerProps { }

const ExploreContainer: React.FC<ContainerProps> = () => {
  return (
    <div className="container">
      <strong>Skinotype.com</strong>
      <p>The place to be to combate acne</p>
    </div>
  );
};

export default ExploreContainer;
